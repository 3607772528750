/*-------------------------*/
/* cycle-images
/*-------------------------*/
.cycle-images{
    background:#8f654d;
    position: fixed!important;
    z-index:0;
    top: 0;
    left: 0;
}
img.cycle-img {
  min-height: 100%;
  min-width: 100%;
  height: auto;
  position: fixed;
  z-index:0;
  bottom: 0;
  left: 0;
}
.keep-height img.cycle-img{
    top:0;
    height:auto!important;
    min-height:unset!important;
    max-height:66%!important;
}
/*-------------------------*/
/* Media Queries
/*-------------------------*/

@media screen and (max-width: 769px) {
    img.logoBig{
        width:88%;
        margin-top:7%!important;
        padding-top:1%;
        margin-bottom:5%;
    }
    img.cycle-img {
        min-height: 100%;
        min-width: 100%;
        height: auto;
        position: fixed;
        z-index:0;
        bottom: 50px;
        left: 0;
        margin-left:-10vw;
    }
    div.login-btn-wrp{
        position:relative;
        left:0;
        top:6%;
        margin-top:30px;
    }
    .footer {
        position: fixed;
        bottom:0;
        background:#0B61A4;
        padding-bottom:0!important;
        text-align:center;
    }
    .footer .dss{
        margin-top:5px!important;
    }
    .footer a{
        margin-bottom:15px;
    }
    .footer div.copyright{
        margin-top:15px;
        width:100%;
        line-height:38px;
        text-align:center!important;

    }
    .footer .sponsors{
        display:inline-block;
        width:100%;
        padding:5px 10%;
        margin:0;
        font-size:11px;
        line-height:14px;
        text-align: center;
    }
    ul.footer-menu li a i {
       margin-top:10px;
    }
}

@media screen and (min-width: 769px) {
    img.logoBig{
        display:block;
        margin-top:2.5%;
        width:90%;
        max-width:950px;
    }
    div.login-btn-wrp{
        margin-top:60px;
    }
    .footer {
        position: fixed;
        left:0;
        bottom:0;
        text-align:center;
        font-size: 10px;
    }
    .footer .sponsors{
        position:absolute;
        left:0px;
        bottom:0;
    }
    .footer .sponsors{
        display:inline-block;
        position: absolute;
        top:0;
        left:0;
        font-size:11px;
        line-height:12px;
        margin:8px 20px 0 0;
        padding-left:10px;
        text-align: left;
        max-width:360px;
    }

    .footer div.copyright{
        position:absolute;
        right:-20px;
        padding:0 20px;
        top:0;
        height:43px;
        line-height:43px;


    }

}
/*-------------------------*/
/* login
/*-------------------------*/
.login-wrp{
    margin:0 0 0 0;
    position:absolute;
    z-index:99;
    width:100%;
    overflow:hidden;
    display:block;
    color:#fff;
    text-align:center;
}
.login-wrp h1{
    font-size:48px;
    color:#fff;
}
.login-wrp a.log-out{
    background:#002f47!important;
    border-color:#002f47!important;
}
.login-wrp a.log-out:hover{
    background:#e4de77!important;
    border-color:#e4de77!important;
    color:#006186!important;
}
.login-wrp a.btn-info.btn-lg:not(.log-out):hover{
    color:#e4de77!important;
}
img.logoBig{
    display:inline-block;
}
/*
ul.benefits{
    list-style-type: none;
    margin:30px 0 0 0;
    padding:0 20px 0 20px;
    display:inline-block;
    vertical-align: top;
    color:#fff;
    color: rgba(255, 255, 255, 1);
    width:100%;
    text-align: center;
}
ul.benefits li img{
    margin-bottom:10px;
    display:inline-block;
    max-height: 60px;
}
ul.benefits li h3{
    margin:0 0 0 0;
    padding:5px 0 10px 0;
    font-size:19px;
    color:#fff;
    font-weight:700;
    line-height:21px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.9);
    position:relative;
    text-align: center;
    display:inline-block;
}
*/
div.login-btn-wrp{
    display:block;
    text-align:center
}
a.big-blue-btn, a.med-blue-btn{
    display:inline-block;
    background-color:#308ba4;
    color:#fff;
    font-weight:700;
    font-size:36px;
    line-height:30px;
    max-width:95%;
    padding:35px 45px;
    text-align: center;
    border-radius:2px;
    white-space: nowrap;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.alt-wrap{
    display: inline-block;
    text-align: center;
    width:273px;
    max-width:95%;
    margin-top:5px;
    margin-bottom:20px;
}
.alt-wrap a{
    display:inline-block;
    margin-top:5px;
    font-size:14px;
    line-height:24px;
    text-transform: uppercase;
    color:#fff;
}

a.med-blue-btn{
    padding:18px 25px;
    font-size:22px;
}



/*-------------------------*/
/* Faq & info shortcut btn
/*-------------------------*/
ul.footer-menu{
    display:inline-block;
    padding:0;
    margin:0;
}
ul.footer-menu li{
    display:inline;
}
ul.footer-menu li a{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    text-transform: uppercase;
    color:#fff;
}
ul.footer-menu li a{font-size:14px;line-height:26px;vertical-align: middle}

/*-------------------------*/
/* footer
/*-------------------------*/
.footer{

    /*border-color: rgba(255, 255, 255, 0.3);*/
    width:100%;
    padding:8px 0;
    font-size:11px;
    color:#fff;
    background:#0b374f;
}
.help-footer{
    text-align:center;
}
.footer a{
    white-space: nowrap;
    margin:0 5px;
}
.footer.absolute {
    position: absolute;
    left:0;
    bottom:0;
}
.footer .sponsors{
    float:left;
    margin-left:10px;
    color:rgba(255, 255, 255, 0.8);
}
.footer .sponsors img{
    margin-top:-3px;
    max-height:46px;
}
.footer .dss{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    font-weight:bold;
    color:rgba(255, 255, 255, 0.9);
}
.footer .copyright{
    margin-right:20px;
    color:rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    font-size:14px;
    background:#002f47;
}
/*********************
    Help
**********************/
.help-wrp{
    overflow:hidden;
    height:100%;
    background-color:#ccc;
}
.help-top{
    position:relative;
    vertical-align: middle;
    display:block;
}
.help-top .cycle-images{
    position:relative!important;
    overflow:hidden;
    height:auto!important;
}
.help-top .cycle-images img{
    position:relative;
    height:auto!important;
}
.help-top .cycle-images-headline{
    position:absolute;
    top:50%;
    line-height:50px;
    margin-top:-55px;
    width:100%;
    text-align:center;
    font-size:25px;
    font-weight:700;
    color:#fff;
    color:rgba(255, 255, 255, 0.85);
}
.help-top .cycle-images-headline i{
    font-size:85px;
    display:block;
    color:rgba(255, 255, 255, 1);
}
.help-top .help-icon{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    text-transform: uppercase;
    font-size:18vh;
    font-weight:700;
    position:absolute;
    top:50%;
    left:0;
    color:#fff;
    margin:-80px 15px 13% 0;
    line-height:160px;
    width:100%;
    height:170px;
    text-align: center;
}

/* faq */
.faq-content-wrp{
    text-align:center;
    overflow:visible;
    margin:30px 0 215px 0;
    height:auto;
    display:block;
}
.faq-box{
    width:100%;
    margin-top:15px;
    position:relative;
    display:inline-block;
    vertical-align: top;
    text-align:left;
    background:#f8f8f8;
    border-top-right-radius:2px;
    border-bottom-left-radius:2px;
    border-bottom-right-radius:2px;
}
.faq-box.open{
    border-bottom-left-radius:0px;
    border-bottom-right-radius:0px;
}
.faq-box .question{
    min-height:130px;
    height:130px;
    font-weight:700;
    box-sizing: border-box;
    padding:20px;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    color:#123;
    font-size:22px;
    text-transform: uppercase;
    display:table;
    cursor:pointer;
    word-break:normal;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
    text-align: left;
}
.faq-box .collapse-icon{
    position:absolute;
    display: block;
    top:10px;
    left:10px;
    font-size:14px;
    color:#004e6e!important;
}
.faq-box .question div{
    display:table-cell;
    vertical-align: middle;
}
.faq-box .answer{
    position:absolute;
    display:block;
    vertical-align: top;
    top:100%;
    width:100%;
    z-index:999;
    height:auto;
    background:#004e6e;
    font-weight:400;
    color:#fff;
    word-break:normal;
    padding:20px;
    font-size:20px;
    line-height:22px;
    border-bottom-left-radius:2px;
    border-bottom-right-radius:2px;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}
.faq-box .answer a{
    color:#fff;
}
.faq-box .answer .arrow{
    position:absolute;
    top:-12px;
    left:50%;
    margin-left:-10px;
    font-size:30px;
    color:#f8f8f8;
    border:none;
}
.faq-box .answer .answer-response{
    font-size:16px;
    margin-top:20px;
    line-height:20px;
    float:right;
    padding:10px 0 0 0;
}
.faq-box .answer .answer-response a{
    font-weight:normal;
}
.faq-box .answer .answer-response img{
    display:inline-block;
    margin-left:5px;

    height:25px;
    width:25px;
    cursor:pointer;
}
/*info */
.info-content-wrp{
    margin-top:30px;
    margin-bottom:60px;
    text-align:center;
}
.info-content-wrp ul.hash-links{
    width:100%;
    padding:0;
    text-align:left;
    list-style-type: none;
    margin-bottom:0;
}
.info-content-wrp li{
    height:auto;
    margin-top:10px;
}

.info-content-wrp li a.hash-link{
    font-size:18px;
    padding:10px 20px;
    display:block;
    text-transform: uppercase;
    overflow:hidden;
    word-break: normal;
    background:#f8f8f8;
    color:#123;
    font-weight:700;
    border-radius:2px;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}
.info-box{
    background:#f8f8f8;
    padding:4% 8% 8% 8%;
    margin-top:30px;
    border-radius:2px;
    text-align: left;
}
.info-box h2{
    color:#123;
    font-size:180%;
}
.info-box div{
    text-align:left;
    color:#123;
    margin-top:30px;
    font-size:16px;
    box-sizing: border-box;
}
/**************************/
/* logout
/*************************/
a.logout{
     position:absolute;
     z-index:999;
     top:10px;
     right:20px;
     color:#fff;
     font-size:14px;
     border:1px solid #fff;
     padding:5px 10px;
     border-color: rgba(255, 255, 255, 0.3);
     background-color: rgba(0, 0, 0, 0.2);
}

a.logout:hover{
    background-color: rgba(0, 0, 0, 0.3);
}